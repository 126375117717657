import { SettingsParamType, createSettingsParams } from '@wix/tpa-settings';
import { BenefitBulletType } from '../../types/common';
import { Alignment } from '../../utils/alignment';
import { ISettingsParamsDefaults } from './defaultParams';

export enum ButtonStyle {
  // @TODO Use proper adjectives: hollow, rounded, solid, rectangular.
  EMPTY_ROUNDED = 0,
  EMPTY_CORNERED = 1,
  FULL_ROUNDED = 2,
  FULL_CORNERED = 3,
}

export enum WidgetPreset {
  PLAN_LIST = 0,
  SINGLE_PLAN = 1,
}

export type ISettingsParams = {
  planTitleHtmlTag: SettingsParamType.String;
  titleHtmlTag: SettingsParamType.String;
  textAlignment: Alignment;
  buttonStyle: ButtonStyle;
  pageHeaderText: SettingsParamType.String;
  pageSubtitleText: SettingsParamType.String;
  buttonText: SettingsParamType.String;
  freeTrialButtonText: SettingsParamType.String;
  ribbonText: SettingsParamType.String;
  disclaimerText: SettingsParamType.String;
  showPageTitle: SettingsParamType.Boolean;
  showPageSubtitle: SettingsParamType.Boolean;
  showPlanName: SettingsParamType.Boolean;
  showTagline: SettingsParamType.Boolean;
  showBenefits: SettingsParamType.Boolean;
  showBenefitsDividers: SettingsParamType.Boolean;
  showHighlightedPlan: SettingsParamType.Boolean;
  showDisclaimer: SettingsParamType.Boolean;
  showRibbon: SettingsParamType.Boolean;
  showRegularPlanCardShadow: SettingsParamType.Boolean;
  showHighlightedPlanCardShadow: SettingsParamType.Boolean;
  hiddenPlans: SettingsParamType.String;
  visiblePlans: SettingsParamType.String;
  planOrder: SettingsParamType.String;
  highlightedPlan: SettingsParamType.String;
  wereNewSettingsOpened: SettingsParamType.Boolean;
  benefitsBullet: BenefitBulletType;
  useVisiblePlans: SettingsParamType.Boolean;
  preset: WidgetPreset;
  useMobileSettingsInStudio: SettingsParamType.Boolean;
  showImage: SettingsParamType.Boolean;
};

export type PackagePickerSettingsParams = ReturnType<typeof createSettingsParamsWithDefaults>;

export function createSettingsParamsWithDefaults(defaults: ISettingsParamsDefaults = {}) {
  return createSettingsParams<ISettingsParams>({
    planTitleHtmlTag: {
      getDefaultValue: () => 'h2',
    },
    titleHtmlTag: {
      getDefaultValue: () => 'h1',
    },
    textAlignment: {
      key: 'text-alignment',
      getDefaultValue: () => Alignment.CENTER,
    },
    buttonStyle: {
      key: 'button-style',
      getDefaultValue: () => ButtonStyle.FULL_CORNERED,
    },
    pageHeaderText: {
      key: 'page-title-text',
      getDefaultValue: ({ t, getSettingParamValue }) => {
        const textData = getSettingParamValue({
          key: 'pp-text-tab-data',
          inheritFromAppScope: true,
          getDefaultValue: () => undefined as unknown as string,
        });
        return textData?.['page-title-text'] || t('pp.defaults.pageTitle');
      },
    },
    pageSubtitleText: {
      key: 'page-subtitle-text',
      getDefaultValue: ({ t }) => t('pp.defaults.pageSubtitle'),
    },
    buttonText: {
      key: 'button-text',
      getDefaultValue: ({ t, getSettingParamValue }) => {
        const textData = getSettingParamValue({
          key: 'pp-text-tab-data',
          inheritFromAppScope: true,
          getDefaultValue: () => undefined as unknown as string,
        });
        return textData?.['button-text'] || t('pp.defaults.button');
      },
    },
    freeTrialButtonText: {
      key: 'free-trial-button-text',
      getDefaultValue: ({ t, getSettingParamValue }) => {
        const textData = getSettingParamValue({
          key: 'pp-text-tab-data',
          inheritFromAppScope: true,
          getDefaultValue: () => undefined as unknown as string,
        });
        return textData?.['free-trial-button-text'] || t('pp.defaults.freeTrialButton');
      },
    },
    ribbonText: {
      key: 'ribbon-text',
      getDefaultValue: ({ t, getSettingParamValue }) => {
        const textData = getSettingParamValue({
          key: 'pp-text-tab-data',
          inheritFromAppScope: true,
          getDefaultValue: () => undefined as unknown as string,
        });
        return textData?.['ribbon-text'] || t('pp.defaults.ribbon');
      },
    },
    disclaimerText: {
      key: 'disclaimer-text',
      getDefaultValue: ({ t, getSettingParamValue }) => {
        const textData = getSettingParamValue({
          key: 'pp-text-tab-data',
          inheritFromAppScope: true,
          getDefaultValue: () => undefined as unknown as string,
        });
        return textData?.['disclaimer-text'] || '';
      },
    },
    showPageTitle: {
      key: 'show-page-title',
      getDefaultValue: () => {
        return defaults.showPageTitle !== undefined ? defaults.showPageTitle : true;
      },
    },
    showPageSubtitle: {
      key: 'show-page-subtitle',
      getDefaultValue: () => false,
    },
    showPlanName: {
      key: 'show-plan-name',
      getDefaultValue: () => true,
    },
    showTagline: {
      key: 'show-plan-tagline',
      getDefaultValue: () => true,
    },
    showBenefits: {
      key: 'show-plan-benefits',
      getDefaultValue: () => true,
    },
    showBenefitsDividers: {
      key: 'show-benefits-dividers',
      getDefaultValue: () => false,
    },
    showHighlightedPlan: {
      key: 'show-highlighted-plan',
      getDefaultValue: () => true,
    },
    showDisclaimer: {
      key: 'show-disclaimer',
      getDefaultValue: () => false,
    },
    showRibbon: {
      key: 'show-ribbon',
      getDefaultValue: () => true,
    },
    showRegularPlanCardShadow: {
      key: 'show-regular-plan-card-shadow',
      getDefaultValue: () => false,
    },
    showHighlightedPlanCardShadow: {
      key: 'show-highlighted-plan-card-shadow',
      getDefaultValue: ({ getSettingParamValue }) =>
        getSettingParamValue({
          type: SettingsParamType.Boolean,
          key: 'show-regular-plan-card-shadow',
          getDefaultValue: () => false,
        }) || false,
    },
    hiddenPlans: {
      key: 'hidden-plans',
      getDefaultValue: () => '',
    },
    visiblePlans: {
      key: 'visible-plans',
      getDefaultValue: () => '',
    },
    planOrder: {
      key: 'plan-order',
      getDefaultValue: () => '',
    },
    highlightedPlan: {
      key: 'highlighted-plan',
      getDefaultValue: () => '',
    },
    wereNewSettingsOpened: {
      key: 'were-new-settings-opened',
      getDefaultValue: () => !!defaults.wereNewSettingsOpened,
    },
    benefitsBullet: {
      key: 'benefits-bullet',
      getDefaultValue: () => BenefitBulletType.NONE,
    },
    useVisiblePlans: {
      key: 'use-visible-plans',
      getDefaultValue: () => false,
    },
    preset: {
      key: 'preset',
      getDefaultValue: () => WidgetPreset.PLAN_LIST,
    },
    useMobileSettingsInStudio: {
      key: 'use-mobile-settings-in-studio',
      getDefaultValue: () => true,
    },
    showImage: {
      key: 'show-image',
      getDefaultValue: () => false,
    },
  });
}

export default createSettingsParamsWithDefaults();
